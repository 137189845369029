// import AOS from 'aos';
import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init();

  // header
  $('.menu-link').click(function(e) {
      e.preventDefault();
      $(this).parent().toggleClass('active');
  });
  $('.dropdown-link.dropdown').click(function(e) {
      e.preventDefault();
      $(this).toggleClass('active');
  });
  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('#headerMenu').toggleClass('active');
  });

  // transparent header
  // $(window).on('scroll', function () {
  //   if ($(window).scrollTop() > 100) {
  //     $('.header').addClass("min-header");
  //   } else {
  //     $('.header').removeClass("min-header");
  //   }
  // });

  //scroll top
  // $('.back-to-top').click(function(event){
  //   $("html,body").animate({scrollTop: 0}, 1000);
  // });

  // $('.header-mobile-menu').on('click', function () {
  //   $(this).toggleClass('active');
  //   $('.header-menu-outer').toggleClass('active');
  // });

  // $('.menu-link').click(function(e) {
  //     $(this).parent().toggleClass('active').siblings().removeClass('active');
  // });


  // tab
  $(function(){
    var tab = $('.tabs-group .tabs-btn a.tab');
    $(tab.eq(0).addClass('active').attr('href')).addClass('active').siblings('.tab-content').hide();

    tab.click(function(){
        event.preventDefault();
        $($(this).attr('href')).addClass('active').slideDown().siblings('.tab-content').hide();
        $(this).addClass('active').siblings('.active').removeClass('active');
    });
  });

  var enSdgs = new Swiper('#enSdgs', {
    autoplay: {
      delay: 5000,
    },
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    breakpoints: {
      376: {
        slidesPerView: 2,
      },
      801: {
        slidesPerView: 4,
      },
      1281: {
        slidesPerView: 6,
      },
    },
  });

  $("[data-swal]").on('click', function(){
    event.preventDefault();
    let id = $(this).data('swal');
    let data = $("[data-id=" + id + "]").html();
    Swal_lg.fire({
      html: '<div class="swal-details">' + data+ '</div>',
    });
  });

  // btn more
  $('.btn-more').click(function(event){
    $(this).toggleClass('active');
    $(this).parent().siblings('.expend-content').toggleClass('active')
    if($(this).hasClass('active')) {
      $(this).find('span').html('Close')
    }else {
      $(this).find('span').html('More')
    }
  });
});
